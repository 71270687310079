import { ProjectInfo } from "@/static_data/types";
import { ProjectImagesQuery } from "@/__autogenerated__/gatsby-graphql";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { ProjectInfoWithImage } from "./types";

const useProjectInfoBuilder = (
  images: ProjectImagesQuery,
  projects: ProjectInfo[],
): ProjectInfoWithImage[] => {
  return projects.map<ProjectInfoWithImage>(project => {
    const imageSrc = images.allFile.edges.filter(edge => {
      return edge.node.childImageSharp.gatsbyImageData.images.fallback.src.endsWith(
        project.imageName,
      );
    })[0];
    return {
      imageSrc: imageSrc.node.childImageSharp
        .gatsbyImageData as IGatsbyImageData,
      info: project,
    };
  });
};

export default useProjectInfoBuilder;
