import { SiteQueryQuery } from "@/__autogenerated__/gatsby-graphql";
import { graphql, useStaticQuery } from "gatsby";

const useNavLinksQuery = (): SiteQueryQuery => {
  const images = useStaticQuery<SiteQueryQuery>(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);
  return images;
};

export default useNavLinksQuery;
